import tippy from 'tippy.js';
import AOS from 'node_modules/aos/dist/aos';
import "./device";
import lozad from 'lozad'
/*
	Tippy related utils
*/
frappe.provide("tippy_utils");

$.extend(tippy_utils, {

    set_tooltip: function(ele_cls, display_ele_id, _options){
    
        let options = $.extend({}, _options);

        if(_options){
            $.extend(options, _options);
        }

        ele_cls = "."+cstr(ele_cls);

        //
        const template = document.getElementById(display_ele_id);

        if(!options.content){
            // setup html content templates.
            options.content = template?template.innerHTML:null;
        }
        if(!options.content){
            return false;
        }
        try{
            tippy(ele_cls, options);
        }catch(e){
            if(frappe.boot.developer_mode){
                console.error(e);
            }else{
                console.warn(__("Couldn't able to setup the tooltip."));
            }
        }
    }
});
/*
    Lazy Load functionality
*/
frappe.provide("bond_assets.lazyload");
$.extend(bond_assets.lazyload, {

    init: function(){
        const observer = lozad('.lozad', {
            rootMargin: '10px 0px', // syntax similar to that of CSS Margin
            threshold: 0.1, // ratio of element convergence
            enableAutoReload: true // it will reload the new image when validating attributes changes
        });
        observer.observe();
    }
});

/*
    Slider related utils
*/

frappe.provide("slider_utils");
$.extend(slider_utils, {
    setup_slider: function(cls, options){
        const ele = $(`.${cls}`);
        if(ele && ele.length){
            try{
                ele.slick(options)
            }catch(e){
                console.log(e);
            }
        }
    },

    setup_logo_slider(){
        slider_utils.setup_slider("logos-slider",{
            slidesToShow: 5,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 1000,
            arrows: false,
            dots: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });
    },

    setup_slider_bond(){
        slider_utils.setup_slider("js-slider-bond", {
            autoplay: true,
            autoplaySpeed: 3200,
            arrows: false,
            dots: false,
            draggable: false,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            cssEase: 'ease'
        });
        const slider_bond = $(".js-slider-bond");
        const slider_bond_nav = $(".js-slider-bond-nav");
        const slider_hand_move = $('.slider-bond__hand-move');

        slider_bond.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            const phoneBg = $('#phone-bg'),
                withTitle = $('#with-bond-text'),
                withoutTitle = $('#without-bond-text');

            if(nextSlide % 2 !== 0) {
                if (phoneBg.length !== 0 && !phoneBg.hasClass('orange')) {
                    phoneBg.addClass('orange');
                }
                withoutTitle.removeClass('active');
                withTitle.addClass('active');
            } else {
                if (phoneBg.length !== 0 && phoneBg.hasClass('orange')) {
                    phoneBg.removeClass('orange');
                }
                withoutTitle.addClass('active');
                withTitle.removeClass('active');
                slider_bond_nav.slick('slickNext');
            }

            if (slider_hand_move) {
                slider_hand_move.addClass('animation-move');
            }
        });

        slider_bond.on('afterChange', function(){
            if (slider_hand_move && slider_hand_move.hasClass('animation-move')) {
                slider_hand_move.removeClass('animation-move');
            }
        });

    },

    setup_bond_nav(){
        slider_utils.setup_slider("js-slider-bond-nav",{
            autoplay: false,
            infinite: true,
            accessibility: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            draggable: false,
            swipe: false,
            centerMode: true,
            focusOnSelect: false,
            speed: 500,
            cssEase: 'ease',
            variableWidth: true
        });
    },

    setup_bond_nav2(){
        slider_utils.setup_slider("slider-bond-nav", {
            autoplay: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            asNavFor: '.slider-bond-phone-left, .slider-bond-phone-right',
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true,
            speed: 200,
            cssEase: 'linear',
            variableWidth: true
        });

        const slider_bond_nav2 = $(".slider-bond-nav");
        const slider_hand_move = $(".slider-bond__hand-move");

        slider_bond_nav2.on('beforeChange', function(){
            if (slider_hand_move) {
                slider_hand_move.addClass('animation-move');
            }
        });

        slider_bond_nav2.on('afterChange', function(){
            if (slider_hand_move && slider_hand_move.hasClass('animation-move')) {
                slider_hand_move.removeClass('animation-move');
            }
        });

    },

    setup_slider_bond_left(){
        slider_utils.setup_slider("slider-bond-phone-left",{
            arrows: false,
            dots: false,
            draggable: false,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            cssEase: 'ease',
            asNavFor: '.slider-bond-nav, .slider-bond-phone-right'
        });

    },

    setup_slider_bond_right(){
        slider_utils.setup_slider("slider-bond-phone-right",{
            arrows: false,
            dots: false,
            draggable: false,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            cssEase: 'ease',
            asNavFor: '.slider-bond-nav, .slider-bond-phone-left'
        });

    },

    apply_slider_wide_settings(){
        if(window.innerWidth < 576) {
            $('.logos-wrap').addClass('logos-slider');
        }

        slider_utils.setup_logo_slider();
        slider_utils.setup_slider_bond();
        slider_utils.setup_bond_nav();
        slider_utils.setup_bond_nav2();
        slider_utils.setup_slider_bond_left();
        slider_utils.setup_slider_bond_right();
        slider_utils.setup_login_slider();


        function setHeightBody(el) {
            el.each(function SH(index, item) {
                if ($(item).hasClass('open')) {
                    let elHeight = $(item).prop('scrollHeight');
                    $(item).css('height', elHeight);
                } else {
                    $(item).removeAttr('style');
                }
            });
        }
        
        function resizeHeightBody(el) {
            el.each(function SH(index, item) {
                if ($(item).hasClass('open')) {
                    $(item).css('height', 'auto');
                    $(item).css('height', $(item).prop('scrollHeight'));
                }
            });
        }
        
        $(function() {
            const bondAccordion = $('.bond-accordion__item');
        
            bondAccordion.on('click', function C() {
                bondAccordion.not($(this)).removeClass('open');
                bondAccordion.not($(this)).find('.bond-accordion__body').removeClass('open');
                $(this).toggleClass('open');
                $(this).find('.bond-accordion__body').toggleClass('open');
                setHeightBody(bondAccordion.find('.bond-accordion__body'));
            });
        
            $(window).on('resize', function () {
                resizeHeightBody(bondAccordion.find('.bond-accordion__body'));
            });
        });
    },

    aboutus_slider: function(){
        $(window).on('resize', function () {
            if(window.innerWidth < 1720) {
                slider_utils.aboutus_slider();
            }
        });

        const aboutCards = $(".about-cards");
        if(!aboutCards.length) return;

        if(aboutCards.hasClass("slick-initialized")){
            aboutCards.slick('unslick');
        }
        aboutCards.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 6,
            slidesToScroll: 1,
            swipeToSlide: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: false,
            dots: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1441,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        variableWidth: true,
                    }
                },
            ]
        });
    },

    setup_login_slider(){
        if (window.innerWidth < 992) {
           
        }
    }
});

frappe.provide("bootstrap_settings");
$.extend(bootstrap_settings, {
    setup_hamburger(){
        $(document).on("click", "#hamburger-icon", function(){
            $(this).toggleClass('active');
            $('#js-navbar').toggleClass('show');
            $('html').toggleClass('overflow-hidden');
            return false;
        });
    },
    handle_aos(){
        /*
        // For animation aboutus
        function init_aos(){
            AOS.init({
                once: true,
                duration: 700
            });
        }
        init_aos();

        $(window).on('resize', function () {
            AOS.refresh();
        });
        */
    },
    on_resize(){
        $(window).on('load resize', function () {
            if (window.innerWidth < 992) {
                $('.form-slider').not('.slick-initialized').slick({
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    infinite: false
                });
            } else {
                $('.form-slider.slick-initialized').slick('unslick');
            }

            // setup tooltips
            if ($(window).width() > 991) {
                $('#js-tooltip-id').tooltip('enable');
                $('#js-tooltip-apply').tooltip('enable');
                $('#js-tooltip-services').tooltip('enable');
                $('#js-tooltip-help').tooltip('enable');
                $('#js-tooltip-account').tooltip('enable');
                $('#js-tooltip-notice').tooltip('enable');
                // $('#js-support-btn').tooltip('enable');
            }
            else {
                $('#js-tooltip-id').tooltip('disable');
                $('#js-tooltip-apply').tooltip('disable');
                $('#js-tooltip-services').tooltip('disable');
                $('#js-tooltip-help').tooltip('disable');
                $('#js-tooltip-account').tooltip('disable');
                $('#js-tooltip-notice').tooltip('disable');
                $('#js-support-btn').tooltip('disable');
            }
        });
    },

    setup_popover(){
        let $popover = $('[data-toggle="popover"]');
        if($popover.length){
            $('[data-toggle="popover"]').popover();
        }
    },

    setup_sliders(){
        /*slider form start*/
        if (window.innerWidth < 992) {

        }

    },

    setup_login_page_sliders(){
        if (window.innerWidth < 992) {
            $('.form-slider').slick({
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                // adaptiveHeight: true,
                infinite: false
            });

			$(".slick-grid-col")
                .unwrap()
                .unwrap()
                .parent();
            $('.form-slider').on('afterChange', function(event, slick, currentSlide) {
                if (currentSlide === 1) {
                $('.slick-dots').addClass('revert');
                }
            });
            $('.form-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                if (currentSlide === 1) {
                $('.slick-dots').removeClass('revert');
                }
            });
        }else{
            $('.form-slide.slick-initialized').slick('unslick');
        }
    },

    apply(){
        $(window).scroll(function() {
            if($(window).scrollTop() > 1) {
                $('#js-header').addClass('header-shadow');
            } else {
                $('#js-header').removeClass('header-shadow');
            }
        });
        try{
            bootstrap_settings.setup_hamburger();
            bootstrap_settings.handle_aos();
            bootstrap_settings.on_resize();
            bootstrap_settings.setup_sliders();
            bootstrap_settings.handle_scroll();
            bootstrap_settings.setup_popover();
            bootstrap_settings.handle_scroll();
        }catch(e){
            console.error(e);
        }
    },

    handle_scroll(){
        $(window).scroll(function() {
            if($(window).scrollTop() > 1) {
                $('#js-header').addClass('header-shadow');
            } else {
                $('#js-header').removeClass('header-shadow');
            }
        });
        window.addEventListener('scroll', bootstrap_settings.setAccordTitleSticky);
    },
    
    isTitleAccordSticky(cb) {
        const titleWrap = document.querySelector('.section-faq__title-wrap');
        const accord = document.querySelector('.bond-accordion');
        if (!titleWrap || !accord) return;
        const accordTop = accord.getBoundingClientRect().top;
        const accordHeight = accord.getBoundingClientRect().height;
        const titleTop = titleWrap.getBoundingClientRect().top;
        const titleHeight = titleWrap.clientHeight;
        const height = window.innerHeight;

        if((titleTop - (height / 2 - 200) <= 0) && titleTop >= accordTop) {
            cb(height / 2 - 200);
            return true;
        } else {
            cb(0);
            return false;
        }
    },
    setTitleAccordTop(top) {
        const titleWrap = document.querySelector('.section-faq__title-wrap');
        if(top) {
            titleWrap.style.top = `${top}px`;
        } else {
            titleWrap.style.top = '';
        }
    },
    setAccordTitleSticky() {
        const titleWrap = document.querySelector('.section-faq__title-wrap');
        if(!titleWrap) return;

        if(bootstrap_settings.isTitleAccordSticky(bootstrap_settings.setTitleAccordTop) && window.innerWidth > 991) {
            if(!titleWrap.classList.contains('sticky')) {
                titleWrap.classList.add('sticky');
            }
        } else {
            titleWrap.classList.remove('sticky');
        }
    }
});

frappe.provide("bond_assets.animation");

$.extend(bond_assets.animation, {

    animate_text: function(obj){
        let {selector, text} = obj;
        let el = document.querySelector(selector);
    
        if(!el) return;
        let i = 0;
    
        let timer = setTimeout(function showText() {
            if(el.textContent.length < text.length) {
                el.textContent = text.substr(0, i);
                i++;
                timer = setTimeout(showText, 180);
            }
            }, 180);
    }
});

// Remove mobile tip from the headers
$(".mobile-header-btn").addClass("d-none");

